<template>
   <div class="row profile-view">
    <div class="col-lg-12">
      <div class="iq-card">
        <div class="iq-card-body p-0 pic-sec">
          <div class="iq-edit-list">
            <ul class="iq-edit-profile d-flex nav nav-pills">
              <li class="col-md-2 p-0"> <a class="nav-link active" data-toggle="pill" href="#wfd-teams" @click="filterFun('Teams')">  WFD Teams </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#wfd-pets" @click="filterFun('Pets')"> Pets </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#wfd-babies" @click="filterFun('Babies')">  WFD Babies </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#wfd-cooking-diary" @click="filterFun('Cooking')">  WFD Cooking Diaries </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link" data-toggle="pill" href="#wfd-events" @click="filterFun('Events')">  WFD Events </a> </li>
              <li class="col-md-2 p-0"> <a class="nav-link " data-toggle="pill" href="#wfd-health" @click="filterFun('Health')">Videos</a> </li>
          </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12 mb-3">
       
        <div class="tab-content sm-p-0">
            <AddSocialPost @addPost="addPost"></AddSocialPost>
        <div class="tab-pane fade active show" id="wfd-teams" role="tabpanel">
         
        <b-row class="p-0">
              <b-col lg="8" class="sm-p-0"> 
          <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
           <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal id="modal1"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          </b-col>
          <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
          </b-row>
        </div>
        <div class="tab-pane fade" id="wfd-pets" role="tabpanel">
             
           <b-row class="p-0">
             <b-col lg="8" class="sm-p-0">
       <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
             <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal id="modal1"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
           </b-col>
           <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
          </b-row>
          </div>         
        <div class="tab-pane fade" id="wfd-babies" role="tabpanel">
           <b-row class="p-0">
             <b-col lg="8" class="sm-p-0">
        <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
            <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal id="modal1"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          </b-col>
          <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
            </b-row>
          </div>  
        <div class="tab-pane fade" id="wfd-cooking-diary" role="tabpanel">
               <!-- <div id="post-modal-data" class="iq-card"> -->
                  <!-- <AddSocialPost @addPost="addPost"></AddSocialPost> -->
                  <!-- <div  v-for="post in socialPosts" :key="post.id">
                    <social-post :post="post"></social-post>
                  </div> -->
             <!-- </div> -->
          <b-row class="p-0">
             <b-col lg="8" class="sm-p-0">
        <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
            <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal id="modal1"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          </b-col>
          <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
            </b-row>
          </div>  
        <div class="tab-pane fade" id="wfd-events" role="tabpanel">
              <b-row class="p-0">
             <b-col lg="8" class="sm-p-0">
        <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
          <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal id="modal1"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          </b-col>
          <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
            </b-row>
          </div>  
        <div class="tab-pane fade" id="wfd-health" role="tabpanel">
           <b-row class="p-0">
             <b-col lg="8" class="sm-p-0">

            


          
          <!-- <div class="row pt-4 px-3 picture-section iq-card m-0 sm-p-0"> -->
           <div  v-for="post in socialPosts" :key="post.id">
                <social-post :post="post"  ></social-post>
            </div>
            <!-- <b-modal :id="'modal-'+modalId"  :title="modalCaption" hide-footer size="xl">
                <div   class="row pt-4 px-3 picture-section  m-0 sm-p-0">
            <div v-for="img in modalImages" :key="img.id"  class="col-md-6 col-sm-6 p-3">   
            <div class="card iq-card">
            <div class="card-body iq-card-body profile-page p-0">
              <div class="profile-header-image">
                <div class="cover-container">
                  <img :src="img" alt="profile-bg" class="rounded img-fluid w-100">
                </div>
              </div>
            </div>     
        </div>
        
            </div>
          </div>   
                
            </b-modal> -->
          <!-- </div>      -->
          <p v-if="allLoadError" class="text-center mx-1">{{allLoadError}}</p>
          <span v-else><infinite-loading :identifier="infiniteId" @infinite="infiniteHandler"></infinite-loading></span>
          </b-col>
          <b-col lg="4" class="sm-p-0">          
            <recent-birthdate/>
            <!-- <iq-card :style="webanner" class="web-banner">
              <template v-slot:headerTitle>
              </template>
            </iq-card> -->
              <events/>
            
            </b-col>
            </b-row>
          </div>  
        </div>     
    </div>
 
  </div>
    

</template>
<script>
import iqCard from '../../../components/socialvue/cards/iq-card.vue'
import { socialvue } from '../../../config/pluginInit'
import About from './ProfileFriends/About'
import AddSocialPost from '../../Apps/Social/Components/AddSocialPost'
import { Posts } from '../../../FackApi/api/SocialPost'
import ProfileImage from './ProfileImage'
import SocialPost from '../../Apps/Social/Components/SocialPost'
import axios from '../../../components/axios'
import InfiniteLoading from 'vue-infinite-loading';
import RecentBirthdate from '../Bithdate/RecentBirthdate'
import Events from '../../Apps/Social/Components/Events'
export default {
  components: {InfiniteLoading, iqCard, About, AddSocialPost, Posts, ProfileImage,SocialPost,RecentBirthdate,Events},
  name: 'PictureAlbum',
  mounted () {
    socialvue.index()
    axios.defaults.headers["Authorization"] = 
    "Bearer " + localStorage.getItem("token");

    
  },
  data () {
    return {
      socialPosts: [],
      page:1,
      last_page:1,
      allLoadError:'',
      cat:'Teams',
      infiniteId: +new Date(), 
      is_like:'',
      modalImages:[],
      modalCaption:'',
      modalId:'',
      daybanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic2.png')})`
        },
       webanner: {
          backgroundImage: `url(${require('@/assets/images/dashboard-graphic1.png')})`
        },
    }
   },
  methods: {
    async addPost (post) {
      // this.socialPosts.unshift(post)
       var parent  = this
      const config = {
          method: "post",
          url: "createpost",
          data:post,
          headers: { 'content-type': 'multipart/form-data' }
      };
  
        await axios(config).then(res => {
        
          parent.socialPosts.unshift(res.data.post)
          this.page += 1;
          // this.$root.$emit('user', res.data.user);
          // this.$router.push('/')
        }).catch(err => {
            console.log(err.response);
            // if(typeof err.response.data !== 'undefined' && err.response.data ){
            //   alert('Caption is required');
            // }
        })
    },
    filterFun(cat){
      this.page = 1;
      this.socialPosts = [];
      this.infiniteId += 1;
      this.cat = cat;
      this.allLoadError = ''
    },
    showAlbum(images,desc,id){
      this.modalImages = images;
      this.modalCaption = desc
      this.modalId = id
    },
    async infiniteHandler($state) {
      var parent = this
      var config = {
      method: "get",
      url: 'albums',
      params: {
          cat:parent.cat,
          page: parent.page,
      },
    };

      if(parent.page <= parent.last_page){
        await axios(config).then((response) => {
          parent.last_page = response.data.postpaginate.last_page;
          if (parent.page <= response.data.postpaginate.last_page) {
            // parent.page += 1;

          
            if (response.data.posts.length) {
                parent.page += 1;
                parent.socialPosts.push(...response.data.posts)
                $state.loaded();
            } else{
              parent.allLoadError = '(: Not Found '
            }
            // this.list.push(...data.hits);
            // parent.socialPosts.push(...response.data.posts)
            // $state.loaded();
          } else {
            $state.complete();
          }
        }).catch( err => {
          console.log(err)
          
        });
      }else{
        this.allLoadError = '(: Not Found '
      }
    
      
    },
     async isLiked (postLike,post) {
      post.is_like = postLike

      if (postLike) {
        post.likes += 1
        this.is_like = 1;
      } else {
        post.likes -= 1
        this.is_like = 0;
      }
      

      const config = {
          method: "post",
          url: "like",
          data:{
            post_id:post.id,
            is_like:this.is_like,
            like_for:1
          },
      };

      await axios(config).then(res => {
      }).catch(err => {
          console.log(err.response);
      })
    },
  }
}
</script>
